import gql from "graphql-tag";

export default gql`
  query UserNotifications($id: ID!) {
    notifications( where: {createdBy: {id: $id}} ){
      id,
      text,
      createdAt,
      link,
      isRead,
      createdBy{
        id
      }
    }
  }
`;

