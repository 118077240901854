import gql from 'graphql-tag';

export default gql`
  mutation DeletePushSubscription($id: ID!){
    deletePushSubscription(
      id: $id
    ) {
      id
    }
  }
`;
