import React from 'react';
import './CircleNumber.css'

/* Usage:
  <NumberInCircle number={5} /> */
function NumberInCircle(props) {
    const number = props.number < 1000 ? props.number : '999+'

    return (
        <div className={'circle-div' + (props.number < 1000 ? '' : ' wider') + (props.navPropItem ? '' : ' convo')} style={{
            display: props.display ? props.display : 'flex',
            marginLeft: props.marginLeft ? props.marginLeft : '0px',
            backgroundColor: props.backgroundColor ? props.backgroundColor : '#46a4fb'
            }} >
            <p className={props.navPropItem ? 'inbox-circle' : ''} style={{
                paddingTop: (props.paddingTop ? props.paddingTop : '16px'),
                paddingBottom: (props.paddingBottom ? props.paddingBottom : '0px'),
                paddingLeft: (props.paddingLeft ? props.paddingLeft : '0px')
            }}>
                {number}
            </p>
        </div>
    )
}

export default NumberInCircle;