import gql from 'graphql-tag';

export default gql`
  mutation DeleteSubscriptionConnections(
    $id: ID!,
    $organizationId: ID,
    $userId: ID!
  ){
    deletePushSubscriptionConnection(
      id: $id,
      organizationId: $organizationId,
      userId: $userId
    ) {
      id
    }
  }
`;
