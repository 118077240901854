import "./Login.css";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import React, { Component } from "react";
import { Snackbar, Typography } from "@material-ui/core";

import AcceptToS from "../../../mutations/AcceptToS.js";
import AddVerificationCode from "../../../mutations/addVerificationCode.js";
import { Alert } from "@material-ui/lab";
import ChangePassword from "../../../mutations/changePassword.js";
import Image from "../../../components/Image/Image.js";
import LoginMutation from "../../../mutations/Login.js";
import Modal from "react-responsive-modal";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { graphql } from "react-apollo";
import logo from "../../../assets/img/brand/logo_tagline.png";
import { withRouter } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
    alert: false,
    onAcceptToS: false,
    onForgot: false,
    onVCode: false,
    onChangePassword: false,
    needsChangePassword: false,
    toggleSnackbar: false,
    alertInfo: {
      severity: "",
      message: "",
    },
  };

  checkVCode(e) {
    e.preventDefault();
    let inputVCode = this.state.inputVCode;
    let vCode = this.state.vCode;
    if (inputVCode === vCode.toString()) {
      this.setState({
        onVCode: false,
        onChangePassword: true,
        alertInfo: {
          severity: "success",
          message: "Verification code accepted.",
        },
        toggleSnackbar: true,
      });
    } else {
      this.setState({
        onVCode: false,
        alertInfo: {
          severity: "warning",
          message:
            "Seems like it was the wrong verification code. Lets try again.",
        },
        toggleSnackbar: true,
      });
    }
  }

  render() {
    if (localStorage.getItem("token")) {
      const removeList = ["token", "role", "organizationId", "organization"];
      removeList.forEach((item) => localStorage.removeItem(item));
    }
    return (
      <div className="app flex-row align-items-center">
        {this.state.alert}
        <Container>
          <Row className="justify-content-center">
            <Col md="12" lg="6" xl="4">
              <center>
                <Image
                  className="login-img"
                  src={logo}
                  alt="LeadWire Logo"
                  width={250}
                  height="auto"
                />
              </center>
              <CardGroup>
                <Card className="p-4 login-boxshadow">
                  <CardBody>
                    <Form onSubmit={(e) => this._login(e)}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign in to your account</p>
                      <InputGroup className="mb-3">
                        <Input
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </InputGroup>
                      <Row>
                        <div className="w-100 flex justify-content-center">
                          <Button
                            type="submit"
                            className="login-btn"
                            color="primary text-center"
                            onClick={(e) => this._login(e)}
                          >
                            Login
                          </Button>
                          <br />
                        </div>
                      </Row>
                      <span
                        onClick={() => this.setState({ onForgot: true })}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password?
                      </span>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>

        {/* Accept ToS Modal */}
        <Modal
          open={this.state.onAcceptToS}
          onClose={() => this.setState({ onAcceptToS: false })}
          className="pin-modal"
        >
          <div className="p-3">
            <div>
              <Slide in={true} direction="down" mountOnEnter unmountOnExit>
                <h5 className="text-align-center">Terms of Service</h5>
              </Slide>
            </div>
            <div className="text-center">
              <Row>
                <Col lg="12">
                  <Typography className="text-muted" variant="overline">
                    In order to use Lead Wire, we need you to accept the Terms
                    of Service.
                  </Typography>
                  <p>
                    <a
                      href="https://www.leadwireapp.com/terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="flex justify-content-between">
              <Button
                color="danger"
                onClick={() => this.setState({ onAcceptToS: false })}
              >
                CANCEL
              </Button>
              <Button color="success" onClick={() => this._acceptToS()}>
                ACCEPT
              </Button>
            </div>
          </div>
        </Modal>

        {/* Forgot Password */}
        <Modal
          open={this.state.onForgot}
          onClose={() => this.setState({ onForgot: false })}
        >
          <div className="p-3">
            <div>
              <Form onSubmit={(e) => this._addVerificationCode(e)}>
                <h1>Forgot Password</h1>
                <p className="text-muted">
                  We will send you an email with a code to change your password.
                </p>
                <InputGroup className="mb-3">
                  <Input
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </InputGroup>
                <Row>
                  <div className="flex justify-content-end w-100">
                    <Button
                      type="submit"
                      color="primary text-center"
                      onClick={(e) => this._addVerificationCode(e)}
                    >
                      SEND CODE
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>

        {/* VCode */}
        <Modal
          open={this.state.onVCode}
          onClose={() => this.setState({ onVCode: false })}
        >
          <div className="p-3">
            <div>
              <Form onSubmit={(e) => this.checkVCode(e)}>
                <h1>Verification Code</h1>
                {this.state.needsChangePassword ? (
                  <p>It seems like its time to change your password.</p>
                ) : (
                  ""
                )}
                <p className="text-muted">
                  Check your email for a verification code.
                </p>
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Verification Code"
                    onChange={(e) =>
                      this.setState({ inputVCode: e.target.value })
                    }
                  />
                </InputGroup>
                <Row>
                  <div className="flex justify-content-end w-100">
                    <Button
                      type="submit"
                      color="primary text-center"
                      onClick={(e) => this.checkVCode(e)}
                    >
                      Submit
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>

        {/* Change Password */}
        <Modal
          open={this.state.onChangePassword}
          onClose={() => this.setState({ onChangePassword: false })}
        >
          <div className="p-3">
            <div>
              <Form onSubmit={(e) => this._changePassword(e)}>
                <h1>Change Password</h1>
                <p className="text-muted">Let's change your password!</p>
                <InputGroup className="mb-3">
                  <Input
                    type="password"
                    placeholder="New Password"
                    onChange={(e) =>
                      this.setState({ newPassword: e.target.value })
                    }
                  />
                </InputGroup>
                <Row>
                  <div className="flex justify-content-end w-100">
                    <Button
                      type="submit"
                      color="primary text-center"
                      onClick={(e) => this._changePassword(e)}
                    >
                      SAVE
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>

        {/* Snackbar */}
        <Snackbar
          open={this.state.toggleSnackbar}
          direction="down"
          style={{ zIndex: 1000 }}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Slide}
          onClose={() => this.setState({ toggleSnackbar: false })}
        >
          <Alert
            severity={this.state.alertInfo.severity}
            onClose={() => this.setState({ toggleSnackbar: false })}
          >
            {this.state.alertInfo.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  _acceptToS = async () => {
    let { acceptToS } = this.props;

    let variables = {
      userId: this.state.userId,
    };

    try {
      let mutationResponse = await acceptToS({ variables });
      if (mutationResponse.data) {
        this.setState({ onAcceptToS: false });
        this._login();
      }
    } catch (e) {
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An internal error occured while accepting ToS.",
        },
        toggleSnackbar: true,
      });
    }
  };

  _login = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let { loginMutation } = this.props;

    let variables = {
      email: this.state.email,
      password: this.state.password,
    };

    try {
      let mutationResponse = await loginMutation({ variables });
      if (mutationResponse.data) {
        const userId = mutationResponse.data.login.user.id;
        const active = mutationResponse.data.login.user.active;
        const acceptedToS = mutationResponse.data.login.user.acceptedToS;
        const changePassword = mutationResponse.data.login.user.changePassword;
        if (
          active === true &&
          acceptedToS === true &&
          changePassword !== true
        ) {
          localStorage.setItem("token", mutationResponse.data.login.token);
          localStorage.setItem("role", mutationResponse.data.login.user.role);
          localStorage.setItem(
            "organization",
            mutationResponse.data.login.user.organization.name
          );
          localStorage.setItem(
            "organizationId",
            mutationResponse.data.login.user.organization.id
          );
          if (localStorage.getItem("role") === "COUPONS") {
            this.props.history.replace("/c");
          } else {
            const path = localStorage.getItem("redirect");
            localStorage.removeItem("redirect");
            if (path) {
              this.props.history.replace(path);
            } else {
              this.props.history.replace("/dashboard");
            }
          }
        } else if (acceptedToS !== true) {
          this.setState({ onAcceptToS: true, userId });
        } else if (changePassword === true) {
          this.setState({ needsChangePassword: true });
          this._addVerificationCode();
        } else {
          this.setState({
            alertInfo: {
              severity: "warning",
              message: "It seems like this user is not active.",
            },
            toggleSnackbar: true,
          });
        }
      } else {
        this.setState({
          alertInfo: {
            severity: "error",
            message: "An internal error occured while trying to login.",
          },
          toggleSnackbar: true,
        });
      }
    } catch (e) {
      this.setState({
        alertInfo: {
          severity: "error",
          message: "Email or password are invalid.",
        },
        toggleSnackbar: true,
      });
    }
  };

  _addVerificationCode = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let { addVerificationCode } = this.props;

    let vCode = Math.floor(100000 + Math.random() * 900000);
    let trueEmail = this.state.email.toLowerCase();
    let variables = {
      email: trueEmail,
      vCode,
    };

    try {
      let mutationResponse = await addVerificationCode({ variables });
      if (mutationResponse.data) {
        var body = JSON.stringify({
          email: trueEmail,
          vCode: vCode,
        });
        let api = process.env.REACT_APP_LEADWIRE_API + "/vCode";
        await axios.post(api, { body });
        this.setState({
          onForgot: false,
          onVCode: true,
          vCode,
          alertInfo: {
            severity: "success",
            message: "Verification code has been added successfully.",
          },
          toggleSnackbar: true,
        });
      }
    } catch (e) {
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An internal error occured while adding verification code.",
        },
        toggleSnackbar: true,
      });
    }
  };

  _changePassword = async (e) => {
    e.preventDefault();
    let { changePassword } = this.props;

    let trueEmail = this.state.email.toLowerCase();
    let password = this.state.newPassword;
    let variables = {
      email: trueEmail,
      password,
    };

    try {
      let mutationResponse = await changePassword({ variables });
      if (mutationResponse.data) {
        this.setState({
          onForgot: false,
          onVCode: false,
          onChangePassword: false,
          alertInfo: {
            severity: "success",
            message: "Your password was changed successfully! Lets login!",
          },
          toggleSnackbar: true,
        });
      }
    } catch (e) {
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An internal error occured while changing password.",
        },
        toggleSnackbar: true,
      });
    }
  };
}

export default graphql(LoginMutation, { name: "loginMutation" })(
  graphql(AcceptToS, { name: "acceptToS" })(
    graphql(ChangePassword, { name: "changePassword" })(
      graphql(AddVerificationCode, { name: "addVerificationCode" })(
        withRouter(Login)
      )
    )
  )
);
