import gql from 'graphql-tag';

export default gql`
  query Announcements {
    announcements {
      id
      title
      message
      url
      urlText
      createdAt
      published
    }
  }
`;
