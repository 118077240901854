import gql from 'graphql-tag';

export default gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        fname
        lname
        role
        active
        acceptedToS
        changePassword
        organization {
          id
          name
        }
      }
      token
    }
  }
`;
