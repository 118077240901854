import gql from 'graphql-tag';

export default gql`
    mutation updateNotification(
        $id: ID!
        $isRead: Boolean
    ) {
        updateNotification(
            id: $id
            isRead: $isRead
        ) {
            id
        }
    }
`;