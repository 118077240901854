import gql from 'graphql-tag';

export default gql`
  mutation AcceptToS($userId: ID!) {
      acceptToS(
        userId: $userId
      ) {
        id
      }
  }
`;
