// Hotjar.js
import { useEffect } from "react";

const Hotjar = () => {
  useEffect(() => {
    // Check if the environment variable indicates a production environment
    if (
      process.env.REACT_APP_LEADWIRE_API ===
      "https://leadwire-api.herokuapp.com/api"
    ) {
      (function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5195756, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, []);

  return null; // This component does not render anything
};

export default Hotjar;
