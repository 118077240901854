import axios from "axios";

export function registerPush() {
  return navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service worker successfully registered");
      return registration;
    })
    .catch((err) => console.error("Unable to register service worker.", err));
}

export function askPermission(userID) {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    } else {
      console.log("permissionResult is undefined");
    }
  }).then((permissionResult) => {
    if (permissionResult !== "granted") {
      console.log("We aren't granted permission");
      return;
    }
    subscribeUserToPush(userID);
  });
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export function subscribeUserToPush(userID) {
  return navigator.serviceWorker
    .register("/service-worker.js")
    .then(function(registration) {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          "BA1NDtUXZsj7zFsDhUeoznELVGyD2D7r588TJ_3yYW-MPuoeJx2-dcR0VYRVZBVKWEgAF0Vs1SWiELrTuNVf-JA"
        ),
      };
      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then(function(pushSubscription) {
      return JSON.stringify(pushSubscription);
    })
    .then(function(subscription) {
      sendSubscriptionToApi(subscription, userID);
    });
}

async function sendSubscriptionToApi(subscription, userID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LEADWIRE_API + "/pushSubscribe",
      {
        subscription,
        organizationId: localStorage.getItem("organizationId"),
        userId: userID,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.data || !response.data.success) {
      throw new Error("Bad response from server.");
    }

    return response.data;
  } catch (error) {
    throw new Error("Bad status code from server.");
  }
}
