import axios from 'axios'

const url = process.env.REACT_APP_PRISMA_URL

// Receives a list of userID's as it's argument
export async function getOrganizationsByUsers(excludedUserID, userList) {
  if (!userList.constructor === Array) return null;

  // Dynamic creation of the where clause based on userList length
  let where = `{OR: [`;
  for (let user of userList){
    if (user.id === excludedUserID) continue;
    where += `{users_some: { id: "${user.id}" }},`
  }
  where += `]}`

  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    method: 'POST',
    data: {
      query: `
      query {
        organizations(where: ${where}){
          id
        }
      }
      `
    }
  })
  return result.data.data.organizations
}