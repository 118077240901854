import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import { registerPush } from './pushServiceWorkerFunctions';
import register from "./registerServiceWorker";

if (!("serviceWorker" in navigator)) {
  // Service Worker isn't supported on this browser, disable or{ hide UI.
  console.log("Service Worker isn't supported on this browser");
} else if (!("PushManager" in window)) {
  // Push isn't supported on this browser, disable or hide UI.
  console.log("Push isn't supported on this browse");
} else {
  register();
}
ReactDOM.render(<App />, document.getElementById("root"));
