import React, { Component } from "react";

import Error from "../Error/Error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return <Error error={this.state.error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
