import React from "react";
import { useWebSocket } from "./WebSocketContext";

/* Allows the use of websocket in class components.
   Functional components don't need this. */
export function WebSocketHookWrapper(props) {
    const webSocket = useWebSocket();
    const children = React.Children.map(props.children, child => {
        return React.cloneElement(child, {...child.props, webSocket })
    })
    return <>{children}</>
}

    /* If we need a generic wrapper, the code below should work.
       Use in the component that needs to be wraped 
       Ex:
            import WebSocketHookWrapper from ...
            <WebSocketHookWrapper>
                <MyComponent props={props} />
            </WebSocketHookWrapper>
    // return function WrappedComponent(props) {
    //   const webSocket = useWebSocket();
    //   return <Component {...props} webSocket={webSocket} />;
    // }
    */