import { Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import Image from "../../../components/Image/Image";
import React from "react";
import SomethingWentWrongImg from "../../../assets/img/error-illustration.png";
import classes from "./Error.module.css";

const Error = () => {
  const GoBackLink = () => {
    const handleRedirect = () => {
      window.location.href = "/dashboard";
    };

    return (
      <div>
        <Button color="primary" onClick={handleRedirect}>
          <span className={classes["btn-container"]}>
            <FaArrowLeft /> <span>Back to Dashboard</span>
          </span>
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Oops!</h1>
      <Image
        src={SomethingWentWrongImg}
        alt="Something went wrong illustration"
        width={500}
        height={500}
        className={classes.image}
      />
      <h2 className={classes.subtitle}>Looks like something went wrong.</h2>
      <GoBackLink />
    </div>
  );
};

export default Error;
