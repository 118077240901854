import React from "react";

const Image = ({ src, alt, width, height, className }) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width === "auto" ? undefined : width}
      height={height === "auto" ? undefined : height}
      loading="lazy"
      decoding="async"
      style={{ maxWidth: "100%", height: "auto" }}
      className={className || ""}
    />
  );
};

export default Image;
