import axios from 'axios'

const url = process.env.REACT_APP_PRISMA_URL

export async function getUserPushSubsctiptions(userId) {
  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    method: 'POST',
    data: {
      query: `
      query {
        pushSubscriptions(
          where: {
            organizations_some: {id: "${localStorage.getItem('organizationId')}"},
            users_some: {id: "${userId}"}
          }
        ) {
          id
          organizations {
            id
          }
          users {
            id
          }
        }
      }
      `
    }
  })
  return result.data.data.pushSubscriptions
}