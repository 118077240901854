import React, { createContext, useContext, useEffect, useState } from "react";

import io from "socket.io-client";

const WebSocketContext = createContext();

/* Currently, orgPhones isn't used, but may be used in the future to identify 
   which organization is to receive a message */
export function WebSocketProvider({ id, orgId, orgPhones, children }) {
  const [webSocket, setWebSocket] = useState(null);

  useEffect(() => {
    const ws = io(
      process.env.REACT_APP_LEADWIRE_API.slice(0, -4), //Removes the /api
      { query: { id, orgId, orgPhones } }
    );
    setWebSocket(ws);

    return () => ws.close();
  }, [id]);

  //Avoid race condition
  if (!webSocket) return <div />;

  return (
    <WebSocketContext.Provider value={webSocket}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const webSocket = useContext(WebSocketContext);
  if (!webSocket) {
    throw new Error("useWebSocket must be within a WebSocketProvider");
  }
  return webSocket;
}
