import React, { Fragment } from 'react';
import Announcements from '../../queries/Announcements.js';
import { graphql, compose } from 'react-apollo';
import './AnnouncementBanner.css';

function AnnouncementBanner(props) {
   
    const { announcements } = props;
    let announcement = announcements.announcements ? announcements.announcements : [];
    let published = announcement.filter(a => a.published === true)[0];

    return (
        <Fragment>
            {announcement.length > 0 && published ?
                <div className="ann">
                    <p className="ann">{published.title}: {published.message}  
                        <a href={published.url}> {published.urlText}</a>
                    </p>
                </div>
                : 
                null}
        </Fragment>

    );
}

export default compose(
    graphql(Announcements, { name: 'announcements' })
)(AnnouncementBanner);
